// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-business-tsx": () => import("./../src/pages/blog/business.tsx" /* webpackChunkName: "component---src-pages-blog-business-tsx" */),
  "component---src-pages-blog-celebrations-tsx": () => import("./../src/pages/blog/celebrations.tsx" /* webpackChunkName: "component---src-pages-blog-celebrations-tsx" */),
  "component---src-pages-blog-education-tsx": () => import("./../src/pages/blog/education.tsx" /* webpackChunkName: "component---src-pages-blog-education-tsx" */),
  "component---src-pages-blog-events-tsx": () => import("./../src/pages/blog/events.tsx" /* webpackChunkName: "component---src-pages-blog-events-tsx" */),
  "component---src-pages-blog-guide-tsx": () => import("./../src/pages/blog/guide.tsx" /* webpackChunkName: "component---src-pages-blog-guide-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-speech-tsx": () => import("./../src/pages/blog/speech.tsx" /* webpackChunkName: "component---src-pages-blog-speech-tsx" */),
  "component---src-pages-blog-tips-tsx": () => import("./../src/pages/blog/tips.tsx" /* webpackChunkName: "component---src-pages-blog-tips-tsx" */),
  "component---src-pages-blog-work-remotely-tsx": () => import("./../src/pages/blog/work-remotely.tsx" /* webpackChunkName: "component---src-pages-blog-work-remotely-tsx" */),
  "component---src-pages-data-protection-tsx": () => import("./../src/pages/data-protection.tsx" /* webpackChunkName: "component---src-pages-data-protection-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-presentations-tsx": () => import("./../src/pages/live-presentations.tsx" /* webpackChunkName: "component---src-pages-live-presentations-tsx" */),
  "component---src-pages-live-subtitles-tsx": () => import("./../src/pages/live-subtitles.tsx" /* webpackChunkName: "component---src-pages-live-subtitles-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-webinars-ro-tsx": () => import("./../src/pages/webinars-ro.tsx" /* webpackChunkName: "component---src-pages-webinars-ro-tsx" */),
  "component---src-pages-webinars-tsx": () => import("./../src/pages/webinars.tsx" /* webpackChunkName: "component---src-pages-webinars-tsx" */)
}

